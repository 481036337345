<template>
  <div class="flex flex-col space-y-5">
    <div class="min-w-0 flex-1">
      <h2
        class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"
      >
        Training Links
      </h2>
    </div>

    <div class="relative">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300" />
      </div>
      <div class="relative flex items-center justify-between">
        <span
          class="bg-white pr-3 text-base font-semibold leading-6 text-gray-900"
          >Resources</span
        >
        <button
          type="button"
          class="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <PlusIcon
            class="-ml-1 -mr-0.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span>Add a Resource</span>
        </button>
      </div>
    </div>

    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-base font-semibold leading-6 text-gray-900">
          Bathu Academy - Introduction to Bathu
        </h3>
        <div class="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi,
            totam at reprehenderit maxime aut beatae ad.
          </p>
        </div>
        <div class="mt-3 text-sm leading-6">
          <a
            href="#"
            class="font-semibold text-indigo-600 hover:text-indigo-500"
          >
            Learn more about this
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-base font-semibold leading-6 text-gray-900">
          Bathu Academy - Introduction to Bathu
        </h3>
        <div class="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi,
            totam at reprehenderit maxime aut beatae ad.
          </p>
        </div>
        <div class="mt-3 text-sm leading-6">
          <a
            href="#"
            class="font-semibold text-indigo-600 hover:text-indigo-500"
          >
            Learn more about this
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-base font-semibold leading-6 text-gray-900">
          Bathu Academy - Introduction to Bathu
        </h3>
        <div class="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi,
            totam at reprehenderit maxime aut beatae ad.
          </p>
        </div>
        <div class="mt-3 text-sm leading-6">
          <a
            href="#"
            class="font-semibold text-indigo-600 hover:text-indigo-500"
          >
            Learn more about this
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-base font-semibold leading-6 text-gray-900">
          Bathu Academy - Introduction to Bathu
        </h3>
        <div class="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi,
            totam at reprehenderit maxime aut beatae ad.
          </p>
        </div>
        <div class="mt-3 text-sm leading-6">
          <a
            href="#"
            class="font-semibold text-indigo-600 hover:text-indigo-500"
          >
            Learn more about this
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-base font-semibold leading-6 text-gray-900">
          Bathu Academy - Introduction to Bathu
        </h3>
        <div class="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi,
            totam at reprehenderit maxime aut beatae ad.
          </p>
        </div>
        <div class="mt-3 text-sm leading-6">
          <a
            href="#"
            class="font-semibold text-indigo-600 hover:text-indigo-500"
          >
            Learn more about this
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
    <div class="bg-white">
      <div class="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
        <div
          class="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16"
        >
          <h2
            class="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl"
          >
            Boost your productivity today.
          </h2>
          <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
            Incididunt sint fugiat pariatur cupidatat consectetur sit cillum
            anim id veniam aliqua proident excepteur commodo do ea.
          </p>
          <div class="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="#"
              class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >Get started</a
            >
            <a href="#" class="text-sm font-semibold leading-6 text-white"
              >Learn more <span aria-hidden="true">→</span></a
            >
          </div>
          <svg
            viewBox="0 0 1024 1024"
            class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
            aria-hidden="true"
          >
            <circle
              cx="512"
              cy="512"
              r="512"
              fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
              fill-opacity="0.7"
            />
            <defs>
              <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                <stop stop-color="#7775D6" />
                <stop offset="1" stop-color="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PlusIcon } from "@heroicons/vue/20/solid";
export default {
  components: {
    PlusIcon
  }
};
</script>
