<template>
  <div class="bg-white">
    <div class="">
      <vue-pdf-app
        vue-pdf-app
        style="height: 100vh"
        pdf="https://pdfobject.com/pdf/sample.pdf"
      ></vue-pdf-app>
    </div>
  </div>
</template>
<script>
import VuePdfApp from "vue3-pdf-app";

export default {
  components: {
    VuePdfApp
  }
};
</script>
