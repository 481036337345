import { createRouter, createWebHistory } from "vue-router";

import AdminLayout from "../layouts/AdminLayout.vue";
import AuthLayout from "../layouts/AuthLayout.vue";

// Auth
import LoginView from "../views/Auth/LoginView.vue";
import RegisterView from "../views/Auth/RegisterView.vue";

// Admin
import AdminUserDetailsView from "@/views/Admin/AdminUserDetailsView.vue";
import UserEditView from "@/views/Admin/Users/UserEditView";
import AdminCompaniesView from "../views/Admin/AdminCompaniesView.vue";
import AdminHomeView from "../views/Admin/AdminHomeView.vue";
import AdminSettingsView from "../views/Admin/AdminSettingsView.vue";
import DocumentView from "../views/Admin/Documents/DocumentView.vue";
import DocumentsView from "../views/Admin/Documents/DocumentsView.vue";
import ContactHumanResourcesView from "../views/Admin/HumanResources/ContactHumanResourcesView.vue";
import KPIReportView from "../views/Admin/KPI/KPIReportView.vue";
import PerformanceReviewView from "../views/Admin/KPI/PerformanceReviewView.vue";
import UpcomingKPIView from "../views/Admin/KPI/UpcomingKPIView.vue";
import CompanyNewsPostView from "../views/Admin/News/CompanyNewsPostView.vue";
import CompanyNewsView from "../views/Admin/News/CompanyNewsView.vue";
import TeamMembersView from "../views/Admin/Teams/TeamMembersView.vue";
import TraningLinksView from "../views/Admin/Training/TrainingLinksView.vue";

// Common
import CompanyEditView from "@/views/Admin/CompanyEditView.vue";
import NotAuthorised from "../views/NotAuthorised.vue";
import PageNotFound from "../views/PageNotFound.vue";

const routes = [
  // Auth
  {
    path: "/",
    name: "auth-layout",
    component: AuthLayout,
    children: [
      {
        path: "/",
        name: "login-view",
        component: LoginView
      },
      {
        path: "/register",
        name: "register-view",
        component: RegisterView
      },
      {
        path: "/auth/password-reset-request",
        name: "password-reset-request-view",
        component: () =>
          import("@/views/Auth/PasswordReset/PasswordResetRequest.vue")
      },
      {
        path: "/auth/password-reset-confirmation",
        name: "password-reset-confirmation-view",
        component: () =>
          import("@/views/Auth/PasswordReset/PasswordResetConfirmation.vue")
      }
    ]
  },
  // Admin
  {
    path: "/admin",
    name: "admin-layout",
    component: AdminLayout,
    meta: {
      auth: true
    },
    children: [
      {
        path: "/admin/home",
        name: "admin-home-view",
        component: AdminHomeView
      },
      {
        path: "/admin/user/edit/:id",
        name: "user-edit-view",
        component: UserEditView
      },
      {
        path: "/companies/organization/edit/:id",
        name: "company-edit-view",
        component: CompanyEditView
      },
      {
        path: "/admin/user/details/:id",
        name: "admin-user-details-view",
        component: AdminUserDetailsView
      },
      {
        path: "/admin/companies",
        name: "admin-companies-view",
        component: AdminCompaniesView
      },
      {
        path: "/admin/settings",
        name: "admin-settings-view",
        component: AdminSettingsView
      },
      {
        path: "/admin/company-news",
        name: "admin-company-news",
        component: CompanyNewsView
      },
      {
        path: "/admin/company-news-post/:id",
        name: "admin-company-news-post",
        component: CompanyNewsPostView
      },
      {
        path: "/admin/documents",
        name: "admin-documents-view",
        component: DocumentsView
      },
      {
        path: "/admin/document/:id",
        name: "admin-document-view",
        component: DocumentView
      },
      {
        path: "/admin/team-members",
        name: "admin-team-members-view",
        component: TeamMembersView
      },
      {
        path: "/admin/training-links",
        name: "training-links",
        component: TraningLinksView
      },
      {
        path: "/admin/human-resources",
        name: "admin-human-resources",
        component: ContactHumanResourcesView
      },
      {
        path: "/admin/upcoming-kpi",
        name: "admin-upcoming-kpi-view",
        component: UpcomingKPIView
      },
      {
        path: "/admin/kpi-reports",
        name: "admin-kpi-report",
        component: KPIReportView
      },
      {
        path: "/admin/performance-review",
        name: "admin-performance-review",
        component: PerformanceReviewView
      }
    ]
  },

  // Common
  {
    path: "/not-authorised",
    name: "not-authorised",
    component: NotAuthorised
  },
  {
    path: "/:pathMatch(.*)*",
    name: "page-not-found",
    component: PageNotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  routes
});

export default app => {
  app.router = router;
  app.use(router);
};
