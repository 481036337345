import Toaster from "@meforma/vue-toaster";
import { createApp } from "vue";
import App from "./App.vue";
import http from "./http";
import "./index.css";
import auth from "./plugins/auth.js";
import PusherPlugin from "./plugins/pusher";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

const app = createApp(App);

app.use(PusherPlugin, { apiKey: "2e95b0e04d3f392cfd0b", cluster: "eu" });
app.use(Toaster);
app.use(store);
app.use(http);
app.use(router);
app.use(auth);
app.mount("#app");
