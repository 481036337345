<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";

const route = useRoute();
const userId = route.params.id;

const user = ref(null);
const isLoading = ref(true);
const errorMessage = ref(null);

onMounted(() => {
  axios
    .get(`/api-admin/users/${userId}`)
    .then(response => {
      user.value = response?.data;
      isLoading.value = false;
    })
    .catch(error => {
      errorMessage.value = error.response.data.message;
      isLoading.value = false;
    });
});
</script>

<template>
  <div class="p-4 sm:p-6 lg:p-8">
    <div v-if="isLoading" class="text-center">
      <p>Loading...</p>
    </div>
    <div v-if="errorMessage" class="text-red-500 text-center">
      <p>{{ errorMessage }}</p>
    </div>
    <div
      v-if="user"
      class="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6"
    >
      <div class="flex flex-col sm:flex-row items-center sm:space-x-6">
        <div class="w-24 h-24 bg-gray-200 rounded-full overflow-hidden">
          <img
            v-if="user.avatar"
            :src="user.avatar"
            alt="User Avatar"
            class="w-full h-full object-cover"
          />
          <div
            v-else
            class="w-full h-full flex items-center justify-center text-gray-400"
          >
            <span class="text-2xl">{{ user?.name.charAt(0) }}</span>
          </div>
        </div>
        <div class="mt-4 sm:mt-0">
          <h2 class="text-2xl font-semibold text-gray-900">
            {{ user.name }} {{ user.surname }}
          </h2>
          <p class="text-gray-600">{{ user.email }}</p>
          <p class="text-gray-600">{{ user.phone || "No phone number" }}</p>
          <p class="text-gray-600">
            {{ user.is_active ? "Active" : "Inactive" }}
          </p>
        </div>
      </div>
      <div class="mt-8">
        <h3 class="text-xl font-semibold text-gray-900">Roles</h3>
        <div class="flex flex-wrap space-x-4 mt-4">
          <div
            v-for="role in user.roles"
            :key="role.id"
            class="p-4 bg-gray-100 rounded-lg flex-1 mb-4"
          >
            <h4 class="font-semibold">{{ role.name }}</h4>
            <p>{{ role.description }}</p>
          </div>
        </div>
      </div>
      <div class="mt-8">
        <h3 class="text-xl font-semibold text-gray-900">Organisation</h3>
        <div class="flex flex-wrap space-x-4 mt-4">
          <div class="p-4 bg-gray-100 rounded-lg flex-1">
            <h4 class="font-semibold">{{ user.organisation.name }}</h4>
            <p>{{ user.organisation.description }}</p>
            <p>{{ user.organisation.countryName }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
