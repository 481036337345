<template>
  <form>
    <div class="space-y-12">
      <div v-if="isLoading" class="flex justify-center items-center h-32">
        <div
          class="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-indigo-600"
        ></div>
      </div>
      <div v-else>
        <div v-if="user" class="border-b border-gray-900/10 pb-12">
          <h2 class="text-base font-semibold leading-7 text-gray-900">
            Personal Information
          </h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">
            User Information and roles assigned to them in the organisation.
          </p>

          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div
              class="sm:col-span-6 flex flex-col items-center sm:flex-row sm:items-start sm:gap-x-4"
            >
              <div class="w-32 h-32 rounded-full overflow-hidden bg-gray-200">
                <img
                  v-if="user.avatar"
                  :src="user.avatar"
                  alt="User Avatar"
                  class="w-full h-full object-cover"
                />
                <div
                  v-else
                  class="w-full h-full flex items-center justify-center text-2xl text-gray-700"
                >
                  {{ user.name.charAt(0) + user.surname.charAt(0) }}
                </div>
              </div>
              <input
                type="file"
                name="uploadedFile"
                id="uploadedFile"
                autocomplete="uploadedFile"
                @change="handleFileChange"
                accept=".jpg,.jpeg,.png"
                multiple
                class="block w-full sm:w-auto mt-4 sm:mt-0 sm:ml-4 rounded-md border-0 py-1.5 text-gray-900 ring-inset ring-red-300 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div class="sm:col-span-3">
              <label
                for="first-name"
                class="block text-sm font-medium leading-6 text-gray-900"
                >First name</label
              >
              <div class="mt-2">
                <input
                  type="text"
                  v-model="user.name"
                  name="first-name"
                  id="first-name"
                  autocomplete="given-name"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div class="sm:col-span-3">
              <label
                for="last-name"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Last name</label
              >
              <div class="mt-2">
                <input
                  type="text"
                  v-model="user.surname"
                  name="last-name"
                  id="last-name"
                  autocomplete="family-name"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label
                for="email"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Email address</label
              >
              <div class="mt-2">
                <input
                  id="email"
                  name="email"
                  v-model="user.email"
                  type="email"
                  autocomplete="email"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label
                for="phone"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Phone number</label
              >
              <div class="mt-2">
                <input
                  id="phone"
                  name="phone"
                  v-model="user.phone"
                  type="phone"
                  autocomplete="phone"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label
                for="country"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Is active</label
              >
              <div class="mt-2">
                <select
                  id="is_active"
                  name="is_active"
                  v-model="user.is_active"
                  autocomplete="is_active"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option :value="true">Active</option>
                  <option :value="false">Inactive</option>
                </select>
              </div>
            </div>

            <div class="col-span-full">
              <label
                for="street-address"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Role</label
              >
              <div class="flex space-x-4">
                <div class="mt-2" v-for="role in user.roles" :key="role.id">
                  <input
                    type="text"
                    name="street-address"
                    id="street-address"
                    v-model="role.name"
                    autocomplete="street-address"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div class="col-span-full">
              <h2 class="text-base font-semibold leading-7 text-indigo-600">
                Organisation
              </h2>
              <p
                class="text-xl font-bold tracking-tight text-gray-900 sm:text-4xl"
              >
                {{ user.organisation?.name }}
              </p>
              <p class="text-md leading-8 text-gray-600">
                {{ user.organisation?.description }} in
              </p>
              <p class="text-md leading-8 text-gray-600">
                {{ user.organisation?.countryName }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-6" v-if="!isLoading">
      <button
        @click="back"
        type="button"
        class="text-sm font-semibold leading-6 text-gray-900"
      >
        Cancel
      </button>
      <button
        type="button"
        @click="updateUser"
        class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Save
      </button>
    </div>
  </form>
</template>

<script>
export default {
  created() {
    this.getUser();
  },
  data() {
    return {
      form: {
        uploadedFile: null
      },
      user: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        avatar: "",
        is_active: true
      },
      isLoading: false
    };
  },
  methods: {
    getUser() {
      this.isLoading = true;
      this.$http
        .get(`/api-admin/users/${this.$route.params.id}`)
        .then(response => {
          this.user = response.data;
        })
        .catch(error => {
          this.$toast.error(
            "Error loading user: " + error.response.data.message
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handleFileChange(event) {
      this.isLoading = true;
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        this.form.uploadedFile = selectedFile;
        const form = new FormData();
        form.append("file", selectedFile);
        form.append("folder", "user-avatar");

        this.$http
          .post("/api-human-resource/file-manager", form)
          .then(response => {
            this.$http
              .post("/api-auth/user-update", { avatar: response.data.full_url })
              .then(response => {
                this.user.avatar = response.data.full_url;
                this.getUser();
                this.$toast.success("Profile Image Updated", {
                  position: "top"
                });
              })
              .catch(error => {
                this.$toast.error(
                  "Error updating profile: " + error.response.data.message
                );
                console.log(error);
              });
          })
          .catch(err => {
            this.error = err.message;
            this.form.uploadedFile = null;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    updateUser() {
      this.$http
        .put(`/api-admin/users/${this.$route.params.id}`, this.user)
        .then(response => {
          this.$toast.success("User updated successfully!", {
            position: "top"
          });
        })
        .catch(error => {
          this.$toast.error(
            "Error updating user: " + error.response.data.message
          );
        });
    },
    back() {
      this.$router.push("/admin/home");
    }
  }
};
</script>
