<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center mb-8">
      <div class="sm:flex-auto">
        <h1 class="text-base font-semibold leading-6 text-gray-900">Users</h1>
        <p class="mt-2 text-sm text-gray-700">
          A list of all the users in your organisation including their name,
          title, email and role.
        </p>
      </div>
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none" @click="onToggleSearch">
        <MagnifyingGlassIcon
          v-if="!showSearch"
          class="text-gray-400 hover:text-indigo-600 h-6 w-6 shrink-0 cursor-pointer"
          aria-hidden="true"
        />
        <div v-else>
          <XMarkIcon
            class="text-gray-400 hover:text-indigo-600 h-6 w-6 shrink-0 cursor-pointer"
            aria-hidden="true"
          />
        </div>
      </div>
    </div>

    <form
      v-if="showSearch"
      class="space-y-4 md:flex md:space-x-4 md:space-y-0"
      @submit.prevent="onSearch"
    >
      <div class="flex-1">
        <input
          v-model="userSearchTerm"
          name="search"
          type="text"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>

      <div>
        <button
          type="submit"
          class="flex flex-shrink w-full justify-center rounded-md bg-indigo-600 px-8 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Search
        </button>
      </div>
    </form>
    <div v-if="isLoading">Loading...</div>
    <div v-if="errorMessage !== '' && errorMessage !== null">
      {{ errorMessage }}
    </div>
    <div v-if="!isLoading && !errorMessage" class="-mx-4 mt-8 sm:-mx-0">
      <table class="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              Name
            </th>
            <th
              scope="col"
              class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Email
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Roles
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Is Active
            </th>
            <th
              scope="col"
              class="relative px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr
            v-for="person in people"
            :key="person.email"
            class="cursor-pointer"
            @click="onViewUserDetails(person.id)"
          >
            <td
              class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0"
            >
              {{ person.name }}
              <dl class="font-normal lg:hidden">
                <dt class="sr-only sm:hidden">Email</dt>
                <dd class="mt-1 truncate text-gray-500 sm:hidden">
                  {{ person.email }}
                </dd>
              </dl>
            </td>
            <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
              {{ person.email }}
            </td>
            <td class="px-3 py-4 text-sm text-gray-500">{{ person.roles }}</td>
            <td class="px-3 py-4 text-sm text-gray-500">
              {{ person.is_active ? "Yes" : "No" }}
            </td>
            <td class="relative px-3 py-4 text-sm text-gray-500">
              <EllipsisVerticalIcon
                class="text-gray-400 hover:text-indigo-600 h-6 w-6 cursor-pointer"
                aria-hidden="true"
                @click.stop="toggleMenu(person.id)"
              />
              <div
                v-if="menuOpen[person.id]"
                class="absolute right-0 z-10 mt-2 w-48 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
              >
                <div class="py-1">
                  <a
                    @click="onEditUser(person.id, $event)"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    Edit
                  </a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <nav
    class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
    aria-label="Pagination"
  >
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700">
        Showing
        {{ " " }}
        <span class="font-medium"> {{ pagination.current_page }}</span>
        {{ " " }}
        to
        {{ " " }}
        <span class="font-medium">{{ pagination.to }}</span>
        {{ " " }}
        of
        {{ " " }}
        <span class="font-medium">{{ pagination.total }}</span>
        {{ " " }}
        results
      </p>
    </div>
    <div class="flex flex-1 justify-between sm:justify-end">
      <button
        @click="loadPaginatedResults('previous')"
        :disabled="isPreviousDisabled"
        :class="{
          'relative inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus-visible:outline-offset-0': true,
          'bg-gray-200 text-gray-400 cursor-not-allowed': isPreviousDisabled,
          'bg-white text-gray-900 hover:bg-gray-50': !isPreviousDisabled
        }"
      >
        Previous
      </button>
      <button
        @click="loadPaginatedResults('next')"
        :disabled="isNextDisabled"
        :class="{
          'relative ml-3 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus-visible:outline-offset-0': true,
          'bg-gray-200 text-gray-400 cursor-not-allowed': isNextDisabled,
          'bg-white text-gray-900 hover:bg-gray-50': !isNextDisabled
        }"
      >
        Next
      </button>
    </div>
  </nav>
</template>

<script>
import {
  MagnifyingGlassIcon,
  XMarkIcon,
  EllipsisVerticalIcon
} from "@heroicons/vue/24/outline";

export default {
  components: {
    MagnifyingGlassIcon,
    XMarkIcon,
    EllipsisVerticalIcon
  },
  data() {
    return {
      pagination: {
        total: 0,
        per_page: 0,
        from: 0,
        to: 0,
        current_page: 0
      },
      isLoading: true,
      showSearch: false,
      userSearchTerm: "",
      people: [],
      menuOpen: {},
      errorMessage: ""
    };
  },
  created() {
    this.fetchAllUsers();
  },
  computed: {
    isPreviousDisabled() {
      return this.pagination.current_page <= 1;
    },
    isNextDisabled() {
      return this.pagination.to >= this.pagination.total;
    }
  },
  methods: {
    fetchAllUsers() {
      this.isLoading = true;
      this.$http
        .get("/api-admin/users")
        .then(response => {
          this.people = response.data.data.map(user => ({
            ...user,
            roles: user.roles.map(({ name }) => name).join(", ")
          }));
          this.pagination.current_page = response.data.current_page;
          this.pagination.total = response.data.total;
          this.pagination.per_page = response.data.per_page;
          this.pagination.from = response.data.from;
          this.pagination.to = response.data.to;
        })
        .catch(error => {
          this.$toast.error(
            error.response?.data.message || "An error occurred",
            {
              position: "top"
            }
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onSearch() {
      this.isLoading = true;
      this.$http
        .get(`/api-admin/users/search?q=${this.userSearchTerm}`)
        .then(response => {
          this.people = response.data.data.map(user => ({
            ...user,
            roles: user.roles.map(({ name }) => name).join(", ")
          }));
        })
        .catch(error => {
          this.$toast.error(
            error.response?.data.message || "An error occurred",
            {
              position: "top"
            }
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onToggleSearch() {
      this.showSearch = !this.showSearch;
    },
    onEditUser(id, event) {
      event.stopPropagation();
      this.$router.push(`/admin/user/edit/${id}`);
    },
    onViewUserDetails(id) {
      if (!this.menuOpen[id]) {
        this.$router.push(`/admin/user/details/${id}`);
      }
    },
    toggleMenu(id) {
      this.menuOpen = {
        ...this.menuOpen,
        [id]: !this.menuOpen[id]
      };
    },
    loadPaginatedResults(type) {
      let page = 0;
      if (type === "next") {
        if (this.pagination.to === this.pagination.total) {
          this.$toast.warning("You are on the last page", {
            position: "top"
          });
          return;
        }
        page = this.pagination.current_page + 1;
      } else {
        page = this.pagination.current_page - 1;
      }

      this.$http
        .get(`/api-admin/users?page=${page}`)
        .then(response => {
          this.people = response.data.data;
          this.pagination.current_page = response.data.current_page;
          this.pagination.total = response.data.total;
          this.pagination.per_page = response.data.per_page;
          this.pagination.from = response.data.from;
          this.pagination.to = response.data.to;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
