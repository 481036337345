<template>
  <form>
    <div class="space-y-12">
      <div v-if="isLoading" class="flex justify-center items-center h-32">
        <div
          class="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-indigo-600"
        ></div>
      </div>
      <div v-else>
        <div v-if="company" class="border-b border-gray-900/10 pb-12">
          <h2 class="text-base font-semibold leading-7 text-gray-900">
            Edit Company Information
          </h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">
            Company Information in the organisation.
          </p>

          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div
              class="sm:col-span-6 flex flex-col items-center sm:flex-row sm:items-start sm:gap-x-4"
            >
              <div class="w-32 h-32 rounded-full overflow-hidden bg-gray-200">
                <img
                  v-if="company.avatar"
                  :src="company.avatar"
                  alt="company logo"
                  class="w-full h-full object-cover"
                />
                <div
                  v-else
                  class="w-full h-full flex items-center justify-center text-2xl text-gray-700"
                >
                  {{ company.name.charAt(0) }}
                </div>
              </div>
              <input
                type="file"
                name="uploadedLogo"
                id="uploadedLogo"
                @change="handleFileChange"
                accept=".jpg,.jpeg,.png"
                class="block w-full sm:w-auto mt-4 sm:mt-0 sm:ml-4 rounded-md border-0 py-1.5 text-gray-900 ring-inset ring-red-300 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div class="sm:col-span-3">
              <label
                for="company-name"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Name</label
              >
              <div class="mt-2">
                <input
                  type="text"
                  v-model="company.name"
                  name="company-name"
                  id="company-name"
                  autocomplete="given-name"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div class="sm:col-span-3">
              <label
                for="description"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Description</label
              >
              <div class="mt-2">
                <input
                  type="text"
                  v-model="company.description"
                  name="description"
                  id="description"
                  autocomplete="description"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label
                for="countryName"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Country Name</label
              >
              <div class="mt-2">
                <input
                  id="countryName"
                  name="countryName"
                  v-model="company.countryName"
                  type="text"
                  autocomplete="countryName"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label
                for="status"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Status</label
              >
              <div class="mt-2">
                <select
                  id="status"
                  name="status"
                  v-model="company.status"
                  autocomplete="status"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option :value="true">Active</option>
                  <option :value="false">Inactive</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-6" v-if="!isLoading">
      <button
        @click="back"
        type="button"
        class="text-sm font-semibold leading-6 text-gray-900"
      >
        Cancel
      </button>
      <button
        type="button"
        @click="updateCompany"
        class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Save
      </button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      form: {
        uploadedLogo: ""
      },
      company: {
        name: "",
        description: "",
        countryName: "",
        status: "",
        avatar: null
      },
      isLoading: true,
      error: null
    };
  },
  created() {
    this.getCompany();
  },
  methods: {
    getCompany() {
      this.$http
        .get("/api-admin/organisations")
        .then(response => {
          const companies = response.data.data;
          const companyId = parseInt(this.$route.params.id, 10);
          const companyData = companies.find(
            company => company.id === companyId
          );

          if (companyData) {
            this.company = {
              name: companyData.name,
              description: companyData.description,
              countryName: companyData.countryName,
              status: companyData.status,
              avatar: companyData.avatar
            };
          }
        })
        .catch(error => {
          this.$toast.error(
            error.response?.data.message || "An error occurred"
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateCompany() {
      const companyId = this.$route.params.id;
      this.$http
        .put(`/api-admin/organisations/${companyId}`, this.company)
        .then(() => {
          this.$toast.success("Company information updated successfully", {
            position: "top"
          });
          setTimeout(() => {
            this.$router.push("/admin/companies");
          }, 1000);
        })
        .catch(error => {
          this.$toast.error(
            error.response?.data.message || "An error occurred"
          );
        });
    },
    handleFileChange(event) {
      this.isLoading = true;
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const form = new FormData();
        form.append("file", selectedFile);
        form.append("folder", "company-logo");

        this.$http
          .post("/api-human-resource/file-manager", form)
          .then(response => {
            this.company.avatar = response.data.full_url;
          })
          .catch(err => {
            this.error = err.message;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    back() {
      this.$router.push("/admin/companies");
    }
  }
};
</script>
