<template>
  <header
    class="absolute inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10"
  >
    <div
      class="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8"
    >
      <div class="flex flex-1 items-center gap-x-6">
        <button
          type="button"
          class="-m-3 p-3 md:hidden"
          @click="mobileMenuOpen = true"
        >
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="h-5 w-5 text-gray-900" aria-hidden="true" />
        </button>
        <img
          class="h-8 w-auto"
          src="https://www.bathu.co.za/cdn/shop/files/Bathu_small_logo-removebg-preview_9137ef15-1182-4b1d-8a22-b106aee164d5.png"
          alt="Your Company"
        />
      </div>
      <nav
        class="hidden md:flex md:gap-x-11 md:text-sm md:font-semibold md:leading-6 md:text-gray-700"
      >
        <a
          v-for="(item, itemIdx) in navigation"
          :key="itemIdx"
          :href="item.href"
          >{{ item.name }}</a
        >
      </nav>
      <div class="flex flex-1 items-center justify-end gap-x-8">
        <button
          type="button"
          class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">View notifications</span>
          <BellIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <a href="/admin/settings" class="-m-1.5 p-1.5">
          <span class="sr-only">Your profile</span>
          <img
            class="h-8 w-8 rounded-full bg-gray-800"
            src="https://images.unsplash.com/photo-1524159730786-4e74a1b78d7d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
          />
        </a>
        <button
          @click="onSignout"
          type="button"
          class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
        >
          Sign out
        </button>
      </div>
    </div>
    <Dialog
      as="div"
      class="lg:hidden"
      @close="mobileMenuOpen = false"
      :open="mobileMenuOpen"
    >
      <div class="fixed inset-0 z-50" />
      <DialogPanel
        class="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-white px-4 pb-6 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10"
      >
        <div class="-ml-0.5 flex h-16 items-center gap-x-6">
          <button
            type="button"
            class="-m-2.5 p-2.5 text-gray-700"
            @click="mobileMenuOpen = false"
          >
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
          <div class="-ml-0.5">
            <a href="" class="-m-1.5 block p-1.5">
              <span class="sr-only">Kaizen Flow</span>
              <img
                class="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </a>
          </div>
        </div>
        <div class="mt-2 space-y-2">
          <a
            v-for="item in navigation"
            :key="item.name"
            :href="item.href"
            class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
            >{{ item.name }}</a
          >
        </div>
      </DialogPanel>
    </Dialog>
  </header>

  <div class="mx-auto max-w-7xl pt-16 lg:flex lg:gap-x-16 lg:px-8">
    <aside
      class="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20"
    >
      <nav class="flex-none px-4 sm:px-6 lg:px-0">
        <ul
          role="list"
          class="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col"
        >
          <li v-for="item in secondaryNavigation" :key="item.name">
            <a
              :href="item.href"
              :class="[
                item.current
                  ? 'bg-gray-50 text-indigo-600'
                  : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
              ]"
            >
              <component
                :is="item.icon"
                :class="[
                  item.current
                    ? 'text-indigo-600'
                    : 'text-gray-400 group-hover:text-indigo-600',
                  'h-6 w-6 shrink-0'
                ]"
                aria-hidden="true"
              />
              {{ item.name }}
            </a>
          </li>
        </ul>
      </nav>
    </aside>

    <main class="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
      <router-view />
    </main>
  </div>
</template>

<script setup>
import { ref } from "vue";
// import { Dialog, DialogPanel, Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import {
  BellIcon,
  ChartBarIcon,
  FolderIcon,
  UserCircleIcon,
  StarIcon,
  Bars3Icon,
  XMarkIcon
} from "@heroicons/vue/24/outline";
import { useAuth } from "@websanova/vue-auth/src/v3";

const auth = useAuth();

const navigation = [
  // { name: 'Admin Profile', href: '/admin/home' },
  // { name: 'Manager Portal', href: '/manager/home' },
  // { name: 'Human Resource Portal', href: '/human-resource/home' },
  // { name: 'Company News', href: '/admin/company-news' }
];
const secondaryNavigation = [
  { name: "Dashboard", href: "/admin/dashboard", icon: UserCircleIcon },
  { name: "Users", href: "/admin/home", icon: UserCircleIcon, current: true },
  {
    name: "Companies",
    href: "/admin/companies",
    icon: StarIcon,
    current: false
  },
  {
    name: "Partners",
    href: "/admin/documents",
    icon: FolderIcon,
    current: false
  },
  {
    name: "Billing",
    href: "/admin/kpi-reports",
    icon: ChartBarIcon,
    current: false
  },
  {
    name: "Subscribers",
    href: "/admin/kpi-reports",
    icon: ChartBarIcon,
    current: false
  }
];

const mobileMenuOpen = ref(false);
// const automaticTimezoneEnabled = ref(true)

const onSignout = () => {
  auth.logout();
};
</script>
